import React, { useEffect, useRef, useState } from 'react';

import PlatanusLogo from '../assets/clients/PlatanusLogo';
import AlisurLogo from '../assets/clients/AlisurLogo';
import C3DLogo from '../assets/clients/C3DLogo';
import SanGeronimoLogo from '../assets/clients/SanGeronimoLogo';
import CorreaSanguinoLogo from '../assets/clients/CorreaSanguinoLogo';
import NuevaAgricolaLogo2 from '../assets/clients/NuevaAgricolaLogo2';
import WDGroup from './WDGroup.jpg';

import './SuccessStories.css';

const companies = [
  { component: SanGeronimoLogo, name: 'San Geronimo' },
  { component: C3DLogo, name: 'C3D' },
  { component: CorreaSanguinoLogo, name: 'Correa Sanguino' },
  { component: AlisurLogo, name: 'Alisur' },
  { component: NuevaAgricolaLogo2, name: 'NuevaAgricola' },
  { component: PlatanusLogo, name: 'Platanus' },
];

const SuccessStories: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [hoveredCompany, setHoveredCompany] = useState<string | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.3 },
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const getTransitionStyle = (delay: number) => ({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(50px)',
    transition: `opacity 0.9s ease ${delay}s, transform 0.9s ease ${delay}s`,
  });

  return (
    <section
      ref={sectionRef}
      className="w-full min-h-screen bg-lightGray text-white flex justify-center items-center"
    >
      <div className="flex flex-col justify-center w-full md:w-2/3 px-6 tracking-wide">
        <h1
          // style={getTransitionStyle(0.1)}
          className="italic font-extrabold text-2xl md:text-3xl text-center text-gray mb-6 md:mb-4"
        >
          CASOS DE ÉXITO
        </h1>
        <p
          style={getTransitionStyle(0.3)}
          className="text-[32px] md:text-[53px] font-medium text-center text-black"
        >
          Acompañamos a nuestros clientes en
        </p>
        <p
          style={getTransitionStyle(0.4)}
          className="text-[32px] md:text-[53px] font-medium text-center text-black"
        >
          todo el proceso de{' '}
          <span
            className="font-extrabold italic text-yellow"
            style={getTransitionStyle(0.5)}
          >
            creación y desarrollo.
          </span>
        </p>
        <p
          style={getTransitionStyle(0.7)}
          className="text-[32px] md:text-[53px] font-medium text-center text-black pb-28"
        >
          ¡Conoce a quienes han confiado en nosotros!
        </p>

        <div
          style={getTransitionStyle(0.9)}
          className="hidden md:flex justify-center items-center space-x-10 h-8"
        >
          <img src={WDGroup} className="h-8" alt="WDGroup" />
          {companies.map(({ component: LogoComponent, name }) => (
            <LogoComponent
              key={name}
              onMouseEnter={() => setHoveredCompany(name)}
              onMouseLeave={() => setHoveredCompany(null)}
              className="h-10"
              variant={hoveredCompany === name ? 'black' : 'black'}
              style={{ transition: 'fill 0.3s ease' }}
            />
          ))}
        </div>

        <div className="md:hidden overflow-hidden w-full h-14 relative">
          <div
            className="flex animate-marquee space-x-8 justify-center items-center"
            style={{ width: 'calc(200%)' }}
          >
            {companies
              // .concat(companies)
              .map(({ component: LogoComponent, name }, index) => (
                <React.Fragment key={index}>
                  <LogoComponent
                    className="h-14"
                    onMouseEnter={() => setHoveredCompany(name)}
                    onMouseLeave={() => setHoveredCompany(null)}
                    variant={hoveredCompany === name ? 'black' : 'black'}
                    style={{
                      transition: 'fill 0.3s ease',
                      minWidth: '25%',
                    }}
                  />
                </React.Fragment>
              ))}
            <img
              src={WDGroup}
              className="h-8"
              alt="WDGroup"
              style={{
                transition: 'fill 0.3s ease',
                minWidth: '25%',
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessStories;
