import React from 'react';
import colors from '../styles/colors';
import Home from './Home';
import SuccessStories from './SuccessStories';
import Clients from './Clients';
import OurServices from './OurServices';
import Services from './Services';
import Team from './Team';
import Faq from './Faq';
import ContactForm from './ContactForm';
import Footer from './Footer';

const Landing: React.FC = () => {
  return (
    <div
      style={{ backgroundColor: colors.black }}
      className="w-screen h-screen scroll-hidden"
    >
      <section id="home">
        <Home />
      </section>
      <section id="success-stories">
        <SuccessStories />
      </section>
      <section id="clients">
        <Clients />
      </section>
      <section id="our-services">
        <OurServices />
      </section>
      <section id="services">
        <Services />
      </section>
      <section id="team">
        <Team />
      </section>
      <section id="faq">
        <Faq />
      </section>
      <section id="contact-form">
        <ContactForm />
      </section>
      <div className="h-20 bg-[#050505]"></div>
      <section id="footer">
        <Footer />
      </section>
    </div>
  );
};

export default Landing;
